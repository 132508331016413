import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ITaxi } from '../models/taxi-association/ITaxi';

@Injectable({
  providedIn: 'root'
})
export class TaxiAssociationService {

  baseUrl = 'https://livelife-admin-httpapi-host.azurewebsites.net/api/app/';

  httpOptions: {
    observe: 'response';
    responseType: 'json';
  };

  currentTaxi: ITaxi;

  constructor(private http: HttpClient) { }

  getTaxi(referenceNumber) {
    // eslint-disable-next-line max-len
    return this.http.get(`${this.baseUrl}taxi-association/taxi-by-reference-number/${environment.tenantId}?referenceNumber=${referenceNumber}`);
  }

  setCurrentTaxi(taxi) {
    this.currentTaxi = taxi;
  }

  // returns taxi from memory
  getCurrentTaxi() {
    // return this.user;
    return new Observable(o => {
      o.next(this.currentTaxi);
    });
  }
}
